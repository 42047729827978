import AsyncStorage from '@react-native-async-storage/async-storage';
import qz from 'qz-tray';
import axios from 'axios';
import { useState } from 'react';

export const qz_connect = () => {
  return new Promise(function (resolve, reject) {
    if (qz.websocket.isActive()) {
      // if already active, resolve immediately
      resolve();
    } else {
      qz.websocket.connect().then(resolve, function retry() {
        qz.websocket.connect({retries: 2, delay: 1}).then(resolve, reject);
      });
    }
  });
};

export const fetchData = () => {
  return new Promise((resolve, reject) => {
    qz_connect()
      .then(() => {
        let printerFound = qz.printers.find();
        return printerFound;
      })
      .then(printersList => {
        AsyncStorage.setItem('printers', JSON.stringify(printersList)).then(
          () => {
            let printerState = {
              isModal: true,
              printersList
            };
            resolve(printerState);
          }
        );
      })
      .catch(e => {
        console.error('Failed to fetch printers:', e);
        reject(e);
      });
  });
};

export const setPrinter = async (setting, msgData, res) => {
  let userId = await AsyncStorage.getItem('userId') || '{}';
  let packingSlip =
      (await AsyncStorage.getItem(`user_selected_printer_packing_slip_${userId}`)) ||
      '';
    let scanningBarcode =
      (await AsyncStorage.getItem(
        `user_selected_printer_order_barcode_label_${userId}`
      )) || '';
    let barcodeLabel =
      (await AsyncStorage.getItem(`print_product_barcode_labels_${userId}`)) || '';
    let shippingLabel =
      (await AsyncStorage.getItem(`print_ss_shipping_labels_${userId}`)) || '';
    let recountReceive =
      (await AsyncStorage.getItem(`user_receive_recount_printer_${userId}`)) || '';
    let receive = (await AsyncStorage.getItem('user_receive_label')) || '';

    if (msgData === 'receive_recount' && recountReceive.length === 0) {
      AsyncStorage.setItem(
        `user_receive_recount_printer_${userId}`,
        JSON.stringify(res)
      );
    }
    if (msgData === 'receive_recount_label' && receive.length === 0) {
      AsyncStorage.setItem('user_receive_label', JSON.stringify(res));
    }
    if (
      msgData === 'packing_slip' &&
      setting.print_packing_slips &&
      packingSlip.length === 0
    ) {
      AsyncStorage.setItem(
        `user_selected_printer_packing_slip_${userId}`,
        JSON.stringify(res)
      );
    }
    if (
      msgData === 'order_barcode' &&
      setting.print_post_scanning_barcodes &&
      scanningBarcode.length === 0
    ) {
      AsyncStorage.setItem(
        `user_selected_printer_order_barcode_label_${userId}`,
        JSON.stringify(res)
      );
    }
    if (
      msgData === 'shipping_label' &&
      shippingLabel.length === 0 &&
      setting.print_ss_shipping_labels
    ) {
      AsyncStorage.setItem(`print_ss_shipping_labels_${userId}`, JSON.stringify(res));
    }
    if (
      msgData === 'product_barcode' &&
      setting.print_product_barcode_labels &&
      barcodeLabel.length === 0
    ) {
      AsyncStorage.setItem(
        `print_product_barcode_labels_${userId}`,
        JSON.stringify(res)
      );
    }
}

export const handlePrint = async (
  res,
  url,
  setting,
  msgData,
  newdimensions,
  dpi
) => {
  // return new Promise((resolve, reject) => {
  let dimensions = {width: 3, height: 1};
  try {
    var config = qz.configs.create(res, {
      size: newdimensions ?? dimensions,
      units: 'in',
      colorType: 'grayscale',
      interpolation: 'nearest-neighbor',
      // density: dpi
    });
    var data = [
      {
        type: 'pixel',
        format: 'pdf',
        flavor: 'file',
        data: url
      }
    ];

    qz.print(config, data)
      .then(async () => {
        setPrinter(setting, msgData, res)
        console.log('QZ Print successful! Check the PDF folder.', url);
        // resolve(true); // Resolve the promise with success
      })
      .catch(e => {
        console.log('QZ Print failed', e);
        // reject(e); // Reject the promise with the error
      });
  } catch {
    window.open(url);
  }
};

export const settingApi = async () => {
  const access_token = await AsyncStorage.getItem('access_token');

  const url = await AsyncStorage.getItem('url');

  let data = await axios({
    method: 'get',
    url: `${url}/settings/get_settings.json`,
    headers: {Authorization: `Bearer ${access_token}`}
  });
  return data?.data?.data?.settings ?? '';
};

export const setQz = async (
  printer,
  msgData
) => {
  try {
    let setting = await settingApi();
    setPrinter(setting, msgData, printer)
  } catch {
    return null;
  }
}
export const handlePrinterSelection = async (
  printer,
  url,
  settings,
  msgData,
  newdimensions,
  dpi
) => {
  try {
    let setting = await settingApi();
    let data = await handlePrint(printer, url, setting, msgData, newdimensions, dpi);
    let printerData = {
      isModal: false,
      printer,
      type: data
      // modalVisible: data
    };
    return printerData;
  } catch {
    return null;
  }
};
