import React, { Component, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
  Image,
} from 'react-native';

import * as Clipboard from 'expo-clipboard';
import globalStyles from '../../style/global';
import styles from '../../style/shippinglabel.js';
import '../../style/shippinglabel.css';
import OrderInfoHeader from '../scanpack/order-info-header';
import DateTimePicker from 'react-datetime-picker';
import datestyle from 'react-datetime-picker/dist/DateTimePicker';
import Panel from '../../commons/panel';
import PanelContent from '../../commons/panelContent';
import Dimension from '../../commons/dimension';
import UpdateRatesButton from '../../commons/upateRatesButton';
import ShortcutModal from '../../commons/shortcutModal';
import { connect } from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import i18n from 'i18n-js';
import { LinearGradient } from 'expo-linear-gradient';
import Tooltip from '../../commons/toolTip';
import shortcutImage from '../../../assets/shortcut-icon.png';
import {
  fetchLabelData,
  fetchRealTimeRates,
  fetchRealTimeRatesSelected,
  createSsLabel,
  setCarrierVisibility,
  updateOrderAddress,
  setRateVisibility,
  asyncSetCarrierVisibility,
  setLabelShortcut,
  setContractedCarrier,
  setPresets
} from '../../actions/orderActions';
import Dropdown from '../../commons/customSelect';
import CommonSelect from '../../commons/commonDropDownSelect';
import CountryCodeList from './countryCodeList';
import { fetchData, handlePrinterSelection } from '../../../qzservice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PrinterShow from '../directPrinting/printerShow';
import moment from 'moment';
import qz from 'qz-tray';
import ReprintSSlabel from './reprintSSlabel';
import ShippingDropDown from './dropDownforShippingLabel';
class ShippingLabel extends Component {
  removeRecordKeysListener = null;
  pounds = 0;
  ounces = 0;
  constructor(props) {
    super(props);
    this.weightRef = React.createRef();
    this.ozWeightRef = React.createRef();
    this.dimensionRef = React.createRef();
    this.addressRef = React.createRef();
    this.stateRef = React.createRef();
    this.postRef = React.createRef();
    this.countryRef = React.createRef();
    this.confirmationRef = React.createRef();
    this.residentialRef = React.createRef();
    this.lengthRef = React.createRef();
    this.widthRef = React.createRef();

    this.heightRef = React.createRef();

    this.defaultShipDate = new Date(
      new Date().setHours(new Date().getHours() + 6)
    );

    this.state = {
      activateEditButton: false,
      weightFocus: false,
      addressFocus: false,
      confirmationFocus: false,
      showModal: false,
      pickedDate: "",
      loaderTitleCarrier: 'Loading...',
      shortcutKeyCombination: null,
      shortcutKeyCombo: {
        carrierName: null,
        serviceName: null,
        shortcutKey: []
      },
      url:"",
      countryCode : '',
      shortcutKeysTotal: [],
      orderAddress: {},
      dimensionData: [],
      selectedDimensionData: {},
      selectedDimensionPreset: {},
      pickerDimensionUnit: 'inches',
      selectedServiceToPurchase: null,
      pickerWeightSelectedOption: 'ounces',
      pickerConfirmationSelectedOption: 'none',
      pickerResidentialSelectedOption: 'true',
      shortcutElement: null,
      fetchRatesVisibility: false,
      poundsValue: '',
      ouncesValue: '',
      pickerSelectedOptionsWeight: [
        {
          label: 'English',
          value: 'ounces',
        },
        {
          label: 'Metric',
          value: 'grams'
        }
      ],

      pickerSelectedOptionsConfirmation: [
        {
          label: 'None',
          value: 'none'
        },
        {
          label: 'Delivery',
          value: 'delivery'
        }
      ],
      pickerResidentialOptions: [
        {
          label: 'Residential',
          value: 'true'
        },
        {
          label: 'Commercial',
          value: 'false'
        }
      ],
      codeToNumber: {
        Digit0: 0,
        Digit1: 1,
        Digit2: 2,
        Digit3: 3,
        Digit4: 4,
        Digit5: 5,
        Digit6: 6,
        Digit7: 7,
        Digit8: 8,
        Digit9: 9
      },
      windowWidth: window.innerWidth,
      available_carriers: null,
      fetchRatesData: null,
      disableRates: null,
      contentVisibility: [],
      carrierDropdownVisibility: [],
      carrierDropdownVisibilityNormalMode: [],
      compError: {
        visible: false,
        message: null
      },
      pickerConfirmationOpen: false,
      pickerWeightOpen: false,
      pickerDimensionOpen: false,
      customAlert: false,
      carrierErrors: {},
      preselectedService: null,
      weightValidation: false,
      flag: false,
      weightValidationError: false,
      focusTarget: null,
      modalVisible:false,
      printers:[],
      selectedPrinter:"",
      openReprintShipping:false,
      printlabelFun: false,
      newState : this.props?.route?.params?.directPrintLabelState,
      messageUrlReprint : "",
      modalVisibleNew:false,
      showPreviouslyPrintedLabel :null,
      showNewState : false


    };

    // isolatedComponent = true used to know if shipping label component is loaded from navigation.navigate() from pages other than OrderDetails page
    this.basicinfo = this?.props?.route?.params?.isolatedComponent
      ? this.props?.route?.params?.basicinfo
      : this.props?.basicinfo;
    this.roles = this?.props?.route?.params?.isolatedComponent
      ? this.props?.route?.params?.userRole?.role
      : this.props?.userRole?.role;
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handlePoundsChange = () => {
    // Convert pounds to ounces
    this.pounds = parseFloat(document.querySelector('#weightForm input[placeholder="Weight"]').value);
    this.ounces = parseFloat(document.querySelector('#weightForm input[placeholder="WeightOunces"]').value)
    let tempPound = this.pounds < 1 ? 0 : this.pounds
    this.ounces = this.ounces < 1 ? 0 : this.ounces
    this.pounds = (tempPound * 1000) + this.ounces ;
    this.setState({ poundsValue: this.pounds.toString()})
    this.ounces = (tempPound * 16) + this.ounces ; // 1 pound = 16 ounces
    this.setState({ ouncesValue: this.ounces.toString() });
  };

  convertGramsToKg = (gramsValue) => {
    // Convert pounds to ounces
    this.pounds = Math.floor(gramsValue / 1000);
    this.ounces = gramsValue % 1000;
    document.querySelector('#weightForm input[placeholder="Weight"]').value = this.pounds
    document.querySelector('#weightForm input[placeholder="WeightOunces"]').value = this.ounces.toFixed(2)
  };

  convertOzToLbs = (gramsValue) => {
    this.pounds = Math.floor(gramsValue / 16);
    this.ounces = gramsValue % 16;
    document.querySelector('#weightForm input[placeholder="Weight"]').value = this.pounds
    document.querySelector('#weightForm input[placeholder="WeightOunces"]').value = this.ounces.toFixed(2)
  };

  convertOuncesToKgAndGrams = (ouncesValue) => {
    if (!isNaN(ouncesValue)) {
      document.querySelector('#weightForm input[placeholder="Weight"]').value = Math.floor(ouncesValue / 35.27396);
      document.querySelector('#weightForm input[placeholder="WeightOunces"]').value = ((kilogramsValue - Math.floor(kilogramsValue)) * 1000).toFixed(1)
    }
  };

  convertGramsToPoundsOunces = (gramsValue) => {
    if (!isNaN(gramsValue)) {
      document.querySelector('#weightForm input[placeholder="Weight"]').value = Math.floor(gramsValue * 0.00220462)
      document.querySelector('#weightForm input[placeholder="WeightOunces"]').value = ((gramsValue * 0.03527396) % 16).toFixed(1)
    }
  };

  toggleSubmitButton = value => {
    if (value === null || value === undefined)
      this.setState(prev => ({
        fetchRatesVisibility: !prev.fetchRatesVisibility
      }));
    else if (value === true) this.setState({ fetchRatesVisibility: true });
    else if (value === false) this.setState({ fetchRatesVisibility: false });
  };

  changeInitialFormValues = (name, value) => {
    if ((name === "weight" || name === 'toState' || name === 'toPostalCode' || name === 'toCountry') && value == "") {
      this.setState({ flag: true, focusTarget: name })
      return
    }
    if ((name=== 'length' || name === 'width' || name === 'height') && value === "") {
      this.setState({ focusTarget: name, flag: true })
    }
    else {
      this.setState({ flag: false })
    }
    if (name === 'weight') {
      this.setState({ weightValidationError: false })
      this.toggleSubmitButton(true);
      let updatedValue = this.state?.pickerWeightSelectedOption !=='ounces' ?
        this.pounds : this.ounces
      value = updatedValue;
      this.setState(prev => {
        let fetchRatesData;
        if (prev.fetchRatesData && prev.fetchRatesData.post_data) {
          fetchRatesData = {
            ...prev.fetchRatesData,
            post_data: {
              ...prev.fetchRatesData.post_data,
              weight: { value, units: this.state.pickerWeightSelectedOption }
            }
          };
        } else if (!prev.fetchRatesData) {
          fetchRatesData = {
            post_data: {
              weight: { value, units: this.state.pickerWeightSelectedOption }
            }
          };
        }
        return { fetchRatesData };
      });
    } else if (name === 'weightUnit') {
      this.handlePoundsChange()
      this.toggleSubmitButton(true);
      this.setState(prev => {
        let fetchRatesData;
        if (prev.fetchRatesData && prev.fetchRatesData.post_data) {
          fetchRatesData = {
            ...prev.fetchRatesData,
            post_data: {
              ...prev.fetchRatesData.post_data,
              weight: {
                value: value !== 'ounces' ? this.pounds : this.ounces,
                units: value
              }
            }
          };
        } else if (!prev.fetchRatesData) {
          fetchRatesData = {
            post_data: {
              weight: {
                value: prev.fetchRatesData?.post_data.weight?.value,
                units: value
              }
            }
          };
        }
        return { fetchRatesData };
      });
    } else if (name === 'dimension') {
      this.setState({ weightValidationError: false })
      this.toggleSubmitButton(true);
      this.setState(prev => {

        let fetchRatesData;
        if (prev.fetchRatesData && prev.fetchRatesData.post_data) {
          fetchRatesData = {
            ...prev.fetchRatesData,
            post_data: {
              ...prev.fetchRatesData.post_data,
             dimensions: (value.length!=0 && value.width!=0 && value.height!=0) ? this.state.selectedDimensionData : ""
            }
          };
        } else if (!prev.fetchRatesData) {
          fetchRatesData = {
            post_data: {
              dimensions: (value.length!=0 && value.width!=0 && value.height!=0) ? this.state.selectedDimensionData : ""
            }
          };
        }
        return { fetchRatesData };
      });
    } else if (
      name === 'toCountry' ||
      name === 'toState' ||
      name === 'toPostalCode' ||
      name === 'confirmation'
    ) {
      this.setState({ weightValidationError: false })
      this.toggleSubmitButton(true);
      this.setState(prev => {
        let fetchRatesData;
        if (prev.fetchRatesData && prev.fetchRatesData.post_data) {
          fetchRatesData = {
            ...prev.fetchRatesData,
            post_data: {
              ...prev.fetchRatesData.post_data,
              [name]: value
            }
          };
        } else if (!prev.fetchRatesData) {
          fetchRatesData = {
            post_data: {
              [name]: value
            }
          };
        }
        return { fetchRatesData };
      });
    } else {
    }
  };

  updateRatesData = () => {
    const fetchRatesData = {
      credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
      post_data: {
        fromPostalCode:
          this.props.ssLabelData?.ss_label_data?.fromPostalCode || '',
        toState: this.props.ssLabelData?.ss_label_data?.toState || '',
        toCountry: this.props.ssLabelData?.ss_label_data?.toCountry || '',
        toPostalCode: this.props.ssLabelData?.ss_label_data?.toPostalCode || '',
        weight: {
          value: this.props.ssLabelData?.ss_label_data?.weight?.value || '',
          units:
            this.props.ssLabelData?.ss_label_data?.weight?.units ||
            this.state.pickerWeightSelectedOption
        },
        confirmation:
          this.state?.pickerConfirmationSelectedOption || 'none',
        residential: this.getResidentialStatus(this.props)
      }
    };
    this.setState({ fetchRatesData });
  }

  getResidentialStatus = (props) => {
    const { ssLabelData } = props;
    if (ssLabelData?.ss_label_data?.shipments[0]?.shipTo?.residential !== undefined && ssLabelData?.ss_label_data?.shipments[0]?.shipTo?.residential !== null) {
      return ssLabelData?.ss_label_data.shipments[0]?.shipTo?.residential;
    }
    return true;
  }


  setInitialFormValues = () => {
    const fetchRatesData = {
      credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
      post_data: {
        fromPostalCode:
          this.props.ssLabelData?.ss_label_data?.fromPostalCode || '',
        toState: this.props.ssLabelData?.ss_label_data?.toState || '',
        toCountry: this.props.ssLabelData?.ss_label_data?.toCountry || '',
        toPostalCode: this.props.ssLabelData?.ss_label_data?.toPostalCode || '',
        weight: {
          value: this.props.ssLabelData?.ss_label_data?.weight?.value || '',
          units:
            this.props.ssLabelData?.ss_label_data?.weight?.units ||
            this.state.pickerWeightSelectedOption
        },
        confirmation:
          this.props?.ssLabelData?.ss_label_data?.confirmation || 'none',
        residential: this.getResidentialStatus(this.props)
      }
    };
    this.setState({ fetchRatesData });

    //Order Date
    const { ssLabelData } = this.props;
    const shipDate = ssLabelData?.ss_label_data?.shipDate;
    if (shipDate && shipDate.length > 0 && moment(shipDate).isAfter(new Date())) {
      this.setState({
        pickedDate: new Date(shipDate)
      });
    } else {
      this.setState({
        pickedDate: this.defaultShipDate
      });
    }
    //Weight
    document.querySelector('#weightForm input[placeholder="WeightOunces"]').value =
      this.props?.ssLabelData?.ss_label_data?.weight?.value || '';
    this.props?.ssLabelData?.ss_label_data?.weight?.units
      ? this.setState({
        pickerWeightSelectedOption:
          this.props?.ssLabelData?.ss_label_data?.weight?.units
      })
      : this.setState({
        pickerWeightSelectedOption: 'ounces'
      });
    //Dimension
    let formattedInitialDimensions = {
      length: this.props?.ssLabelData?.ss_label_data?.dimensions?.length || '',
      width: this.props?.ssLabelData?.ss_label_data?.dimensions?.width || '',
      height: this.props?.ssLabelData?.ss_label_data?.dimensions?.height || '',
      units:
        this.props?.ssLabelData?.ss_label_data?.dimensions?.units || 'inches'
    };
    this.props?.ssLabelData?.ss_label_data?.dimensions
      ? this.setState({
        selectedDimensionData: formattedInitialDimensions,
        pickerDimensionUnit:
          (this.props?.ssLabelData?.ss_label_data?.weight?.units !== 'ounces'
            && this.props?.ssLabelData?.ss_label_data?.dimensions?.units) ||
          'inches'
      })
      : this.setState({
        selectedDimensionData: {
          length: '',
          width: '',
          height: '',
          presetName: '',
          units: this.state.pickerDimensionUnit
        },
        pickerDimensionUnit: 'inches'
      });

    //Shipping address
    document.querySelector(
      '#orderAddressForm input[placeholder="Name"]'
    ).value = this.props.ssLabelData?.ss_label_data?.toName || '';
    document.querySelector(
      '#orderAddressForm input[placeholder="Address 1"]'
    ).value = this.props.ssLabelData?.ss_label_data?.toAddress1 || '';
    document.querySelector(
      '#orderAddressForm input[placeholder="Address 2"]'
    ).value = this.props.ssLabelData?.ss_label_data?.toAddress2 || '';
    document.querySelector(
      '#orderAddressForm input[placeholder="City"]'
    ).value = this.props.ssLabelData?.ss_label_data?.toCity || '';
    document.querySelector(
      '#orderAddressForm input[placeholder="State"]'
    ).value = this.props.ssLabelData?.ss_label_data?.toState || '';
    this.setState({countryCode: this.props.ssLabelData?.ss_label_data?.toCountry || ''})
    document.querySelector(
      '#orderAddressForm input[placeholder="Postal Code"]'
    ).value = this.props.ssLabelData?.ss_label_data?.toPostalCode || '';

    //confirmation
    this.setState({
      pickerConfirmationSelectedOption:
        this.props?.ssLabelData?.ss_label_data?.confirmation || 'none'
    });
  };

  setDimensionData = newDimensionData => {
    this.setState({ dimensionData: [...newDimensionData] });
  };

  toggleCarrierDropdownVisibility = carrierCode => {
    const payload = {
      credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
      carrier_code: carrierCode
    };
    this.props.setContractedCarrier(payload);
    this.setState(prev => ({
      carrierDropdownVisibility: {
        ...prev.carrierDropdownVisibility,
        [carrierCode]: !prev.carrierDropdownVisibility[carrierCode]
      }
    }));
  };

  toggleCarrierDropdownVisibilityNormalMode = carrierCode => {
    this.setState(prev => ({
      carrierDropdownVisibilityNormalMode: {
        ...prev.carrierDropdownVisibilityNormalMode,
        [carrierCode]: !prev.carrierDropdownVisibilityNormalMode[carrierCode]
      }
    }));
    const index = this.state.available_carriers.findIndex(
      el => el.code === carrierCode
    );
    if (!this.state.available_carriers[index].rates?.length) {
      this.fetchUpdatedRatesSelected(carrierCode);
    }
  };

  compressExpandCarrier = carrierCode => {
    this.setState(prev => ({
      contentVisibility: {
        ...prev.contentVisibility,
        [carrierCode]: !prev.contentVisibility[carrierCode]
      }
    }));
    const index = this.state.available_carriers.findIndex(
      el => el.code === carrierCode
    );
    if (
      (this.state.available_carriers[index].visible &&
        !this.state.available_carriers[index].rates?.length) ||
      this.state.available_carriers[index].rates == 'undefined'
    ) {
      this.fetchUpdatedRatesSelected(carrierCode);
    }
  };

  showHideCarrier = carrierCode => {
    const payload = {
      credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
      carrier_code: carrierCode
    };
    const index = this.state.available_carriers.findIndex(
      el => el.code === carrierCode
    );
    const currentCarrierVisibility =
      this.state.available_carriers[index].visible;
    asyncSetCarrierVisibility(payload)
      .then(() => {
        if (
          (!currentCarrierVisibility &&
            !this.state.available_carriers[index].rates) ||
          this.state.available_carriers[index].rates == 'undefined'
        )
          setTimeout(() => {
            this.fetchUpdatedRatesSelected(carrierCode);
          }, 500);
      })
      .catch(e =>
        this.toggleErrorAlert({
          visible: true,
          message: 'Save Carrier Visibility Error! try again later.'
        })
      );

    this.setState(prev => {
      let index = prev.available_carriers.findIndex(
        el => el.code === carrierCode
      );
      let arr = prev.available_carriers;
      arr[index].visible = !arr[index].visible;
      return { available_carriers: arr };
    });
  };

  onclickEditButton = () => {
    if (this.state.activateEditButton) {
      this.setState(prev => ({
        carrierDropdownVisibilityNormalMode: prev.carrierDropdownVisibility
      }));
      const payload = {
        credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
        disable_rates: this.state.disableRates
      };
      this.updateServiceVisibility(payload);
    }
    this.setState({
      activateEditButton: !this.state.activateEditButton
    });
  };

  mapCodeToNumber = (code, key) => {
    if (this.state.codeToNumber[code]) return this.state.codeToNumber[code];
    else return key;
  };

  // recordKeyCombination = e => {
  //   this.setState(prev => {
  //     let shortcutKey = prev.shortcutKeyCombo.shortcutKey?.length
  //       ? [
  //         ...prev.shortcutKeyCombo.shortcutKey,
  //         this.mapCodeToNumber(e.code, e.key.toLowerCase())
  //       ]
  //       : [this.mapCodeToNumber(e.code, e.key.toLowerCase())];
  //     let shortcutKeyCombo = { ...prev.shortcutKeyCombo, shortcutKey };
  //     return { shortcutKeyCombo };
  //   });
  // };

  addShortcut = (carrierName, serviceName) => {
    if (!this.state.showModal) {
      this.setState({ shortcutElement: 'rates' });
      this.removeRecordKeysListener = this.recordKeys();
    }
    if (serviceName !== null) this.setState({ showModal: true });
    this.setState({
      shortcutKeyCombo: {
        carrierName,
        serviceName
      }
    });
  };

  addShortcutDimension = dimensionId => {
    if (!this.state.showModal) {
      this.setState({ shortcutElement: 'dimension' });
      this.removeRecordKeysListener = this.recordKeys();
    }
    if (dimensionId !== null) this.setState({ showModal: true });
    this.setState({
      shortcutKeyCombo: {
        dimensionId
      }
    });
  };

  addShortcutOther = inputName => {
    if (!this.state.showModal) {
      this.setState({ shortcutElement: 'input' });
      this.removeRecordKeysListener = this.recordKeys();
      if (inputName !== null) this.setState({ showModal: true });
      this.setState({
        shortcutKeyCombo: {
          inputName
        }
      });
    }
  };

  saveShortcut = () => {
    const element = this.state.shortcutElement;
    if (element === 'rates') {
      //API call
      const payload = {
        credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
        shortcut: {
          [`${this.state.shortcutKeyCombo.shortcutKey.join('+')}`]:
            this.state.shortcutKeyCombo.serviceName
        }
      };
      this.props.setLabelShortcut(payload);
      //Set shortcut to available carrier state
      let { carrierName, serviceName } = this.state.shortcutKeyCombo;
      const carrierIndex = this.state.available_carriers.findIndex(
        el => el.name === carrierName
      );
      const serviceIndex = this.state.available_carriers[
        carrierIndex
      ].rates.findIndex(el => el.serviceName === serviceName);
      this.setState(prev => {
        let arr = prev.available_carriers;
        arr[carrierIndex].rates[serviceIndex].shortcutKey =
          this.state.shortcutKeyCombo.shortcutKey;
        return { available_carriers: arr };
      });
      //Set shortcut to shortcutKeysTotal state (either new or replace existing)
      this.setState(prev => {
        let shortcutIndex = this.state.shortcutKeysTotal.findIndex(
          el => el.name === this.state.shortcutKeyCombo.serviceName
        );
        if (shortcutIndex !== -1) {
          let arr = prev.shortcutKeysTotal;
          arr[shortcutIndex].shortcutKey =
            this.state.shortcutKeyCombo.shortcutKey;
          return { shortcutKeysTotal: arr };
        } else {
          let obj = {
            type: 'rates',
            name: this.state.shortcutKeyCombo.serviceName,
            shortcutKey: this.state.shortcutKeyCombo.shortcutKey
          };
          let arr = [...prev.shortcutKeysTotal, obj];
          return { shortcutKeysTotal: arr };
        }
      });
    } else if (element === 'dimension') {
      const payload = {
        credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
        shortcut: {
          [`${this.state.shortcutKeyCombo.shortcutKey.join('+')}`]:
            this.state.shortcutKeyCombo.dimensionId
        }
      };
      this.props.setLabelShortcut(payload);
      let { dimensionId } = this.state.shortcutKeyCombo;
      let dimensionIndex = this.state.dimensionData.findIndex(
        el => el.presetName === dimensionId
      );
      this.setState(prev => {
        let arr = prev.dimensionData;
        arr[dimensionIndex].shortcutKey =
          this.state.shortcutKeyCombo.shortcutKey;
        return { dimensionData: arr };
      });
      this.setState(prev => ({
        selectedDimensionData: prev.dimensionData[dimensionIndex],
        selectedDimensionPreset: prev.dimensionData[dimensionIndex].presetName
      }));
      this.setState(prev => {
        let shortcutIndex = this.state.shortcutKeysTotal.findIndex(
          el => el.id === prev.dimensionData[dimensionIndex].id
        );
        if (shortcutIndex !== -1) {
          let arr = prev.shortcutKeysTotal;
          arr[shortcutIndex].shortcutKey =
            this.state.shortcutKeyCombo.shortcutKey;
          return { shortcutKeysTotal: arr };
        } else {
          let obj = {
            type: 'dimension',
            name: this.state.selectedDimensionPreset,
            shortcutKey: this.state.shortcutKeyCombo.shortcutKey
          };
          let arr = [...prev.shortcutKeysTotal, obj];
          return { shortcutKeysTotal: arr };
        }
      });
    } else if (element === 'input') {
      const payload = {
        credential_id: this.props.ssLabelData.ss_label_data.credential_id,
        shortcut: {
          [`${this.state.shortcutKeyCombo.shortcutKey.join('+')}`]:
            this.state.shortcutKeyCombo.inputName
        }
      };
      this.setState(prev => {
        let shortcutIndex = this.state.shortcutKeysTotal.findIndex(
          el => el.id === this.state.shortcutKeyCombo.inputName
        );
        if (shortcutIndex !== -1) {
          let arr = prev.shortcutKeysTotal;
          arr[shortcutIndex].shortcutKey =
            this.state.shortcutKeyCombo.shortcutKey;
          return { shortcutKeysTotal: arr };
        } else {
          let obj = {
            type: 'inputName',
            name: this.state.shortcutKeyCombo.inputName,
            shortcutKey: this.state.shortcutKeyCombo.shortcutKey
          };
          let arr = [...prev.shortcutKeysTotal, obj];
          return { shortcutKeysTotal: arr };
        }
      });

      this.props.setLabelShortcut(payload);
    }
  };

  handleShortcutRetry = () => {
    this.removeRecordKeysListener = this.recordKeys();
    this.setState(prev => {
      let shortcutKey = [];
      return { shortcutKeyCombo: { ...prev.shortcutKeyCombo, shortcutKey } };
    });
  };

  onCloseModal = () => {
    this.removeRecordKeysListener && this.removeRecordKeysListener();
    this.setState({ shortcutKeyCombo: {}, shortcutElement: null, showModal: false });
  };

  // getFocusData = (weight, address, confirmation) => {
  //   this.setState({ weightFocus: weight, addressFocus: address, confirmationFocus: confirmation });
  // };

  focusOnMatchingTarget = shortcutKey => {
    let totalShortcuts = this.props.labelShortcuts;
    let matchingElement = null;
    for (const item in totalShortcuts) {
      if (item.toLowerCase().split('+').join('+') === shortcutKey.join('+')) {
        matchingElement = totalShortcuts[item];
      }
    }
    if (matchingElement) {
      if (matchingElement === 'weight') this.weightRef.current.focus();
      else if (matchingElement === 'dimension')
        this.dimensionRef.current.focus();
      else if (matchingElement === 'shipping address')
        this.addressRef.current.focus();
      else if (matchingElement === 'confirmation') {
        var sel = document.getElementById('test');
        var len = sel.options.length;
        sel.setAttribute('size', len);
        this.confirmationRef.current.focus();
        this.setPickerConfirmationOpen(true);
      }
      else if(matchingElement === 'ozWeight'){
        this.ozWeightRef.current.focus();
      }
      else if(matchingElement === 'residential'){
        var sel = document.getElementById('residentialId');
        var len = sel.options.length;
        sel.setAttribute('size', len);
        this.residentialRef.current.focus();
        this.setPickerConfirmationOpen(true);
      }

      this.state.available_carriers?.forEach(carrier => {
        carrier?.rates?.forEach(rate => {
          if (rate.serviceName === matchingElement) {
            this.setSelectedServiceToPurchase({
              carrierCode: rate.carrierCode,
              serviceCode: rate.serviceCode,
              serviceName: rate.serviceName,
              purchaseConfirm: true
            });
          }
        });
      });
      this.state?.dimensionData?.forEach(dimension => {
        if (dimension?.presetName && dimension.presetName === matchingElement) {
          this.setSelectedDimensionData(dimension);
          this.setSelectedDimensionPreset(dimension.presetName);
          this.setState({ selectedDimensionPreset: matchingElement });
        }
      });
    }
  };

  recordKeys = () => {
    let currentPressed = [];
    //window.removeEventListener('keydown', this.detectKeyPressed);

    const keydownHandler = e => {
      if (
        !currentPressed.find(
          el => el === this.mapCodeToNumber(e.code, e.key.toLowerCase())
        )
      ) {
        currentPressed.push(this.mapCodeToNumber(e.code, e.key.toLowerCase()));
        this.setState(prev => ({
          shortcutKeyCombo: {
            ...prev.shortcutKeyCombo,
            shortcutKey: currentPressed
          }
        }));
      }
    };

    const keyupHandler = e => {
      window.removeEventListener('keydown', keydownHandler);
      window.removeEventListener('keyup', keyupHandler);
      //window.addEventListener('keydown', this.detectKeyPressed);
    };

    window.addEventListener('keydown', keydownHandler);
    window.addEventListener('keyup', keyupHandler);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
      window.removeEventListener('keyup', keyupHandler);
    };
  };

  detectKeys = initialKey => {
    window.removeEventListener('keydown', this.detectKeyPressed);
    let currentPressed = [initialKey];

    const keydownHandler = e => {
      if (
        !currentPressed.find(
          el => el === this.mapCodeToNumber(e.code, e.key.toLowerCase())
        )
      ) {
        currentPressed.push(this.mapCodeToNumber(e.code, e.key.toLowerCase()));
      }
    };

    const keyupHandler = e => {
      this.focusOnMatchingTarget(currentPressed);
      window.removeEventListener('keydown', keydownHandler);
      window.removeEventListener('keyup', keyupHandler);
      window.addEventListener('keydown', this.detectKeyPressed);
    };

    window.addEventListener('keydown', keydownHandler);
    window.addEventListener('keyup', keyupHandler);
  };

  detectKeyPressed = e => {
    if (this.state.fetchRatesVisibility) {
      if (e.key === 'Enter') {
        this.fetchUpdatedRates()
        this.handlePoundsChange()
        var sel = document.getElementById('test');
        sel.setAttribute('size', 0);
        var sel = document.getElementById('residentialId');
        sel.setAttribute('size', 0);
        return
      }
    }
    if (document.activeElement.tagName == 'SELECT') {
      if (e.key === 'Enter') {
        var sel = document.getElementById(document.activeElement.id);
        var len = 1;
        sel.setAttribute('size', len);
      }
      return;
    }
    if (
      document.activeElement.tagName == 'INPUT' ||
      document.activeElement.tagName == 'SELECT' ||
      this.state.showModal ||
      this.state.purchaseLoading ||
      this.state.activateEditButton
    )
      return;

    if (
      document.activeElement.tagName != 'INPUT' &&
      document.activeElement.tagName != 'SELECT' &&
      !this.state.showModal &&
      !this.state.purchaseLoading &&
      !this.state.activateEditButton &&
      e.key.toLowerCase() === 'enter' &&
      this.state?.selectedServiceToPurchase?.serviceName
    ) {
      this.setSelectedServiceToPurchase({
        carrierCode: this.state.selectedServiceToPurchase.carrierCode,
        serviceCode: this.state.selectedServiceToPurchase.serviceCode,
        serviceName: this.state.selectedServiceToPurchase.serviceName,
        purchaseConfirm: true
      });
    }
    e.preventDefault();
    this.detectKeys(this.mapCodeToNumber(e.code, e.key.toLowerCase()));
  };

  toggleServiceVisibility = ({
    carrierName,
    carrierCode,
    serviceName,
    visibility
  }) => {
    this.setState(prev => {
      let disableRates = prev.disableRates;
      if (visibility === true) {
        !disableRates[carrierCode].includes(serviceName) &&
          disableRates[carrierCode].push(serviceName);
      } else {
        disableRates[carrierCode].includes(serviceName) &&
          disableRates[carrierCode].splice(
            disableRates[carrierCode].indexOf(serviceName),
            1
          );
      }
      return { disableRates };
    });
    const carrierIndex = this.state.available_carriers.findIndex(
      el => el.name === carrierName
    );
    const serviceIndex = this.state.available_carriers[
      carrierIndex
    ].rates.findIndex(el => el.serviceName === serviceName);
    this.setState(prev => {
      let arr = prev.available_carriers;
      arr[carrierIndex].rates[serviceIndex].visible =
        !prev.available_carriers[carrierIndex].rates[serviceIndex].visible;
      return { available_carriers: arr };
    });
  };

  setSelectedDimensionData = dimensionData => {
    this.handlePoundsChange();
    this.setState(
      {
        selectedDimensionData: dimensionData,
        pickerDimensionUnit: dimensionData.units
      },
      () => {
       this.changeInitialFormValues('dimension',dimensionData);
      }
    );
  };

  setSelectedDimensionPreset = presetName => {
    this.setState({ selectedDimensionPreset: presetName });
  };

  setPickerDimensionUnit = units => {
    this.setState(
      prev => ({
        pickerDimensionUnit: units,
        selectedDimensionData: {
          ...prev.selectedDimensionData,
          units
        }
      }),
      () => {
        this.setSelectedDimensionData(this.state.selectedDimensionData);
      }
    );
  };

  setPickerConfirmationOpen = value => {
    this.setState({ pickerConfirmationOpen: value });
  };

  setPickerWeightOpen = value => {
    this.setState({ pickerWeightOpen: value });
  };

  setPickerDimensionOpen = value => {
    this.setState({ pickerDimensionOpen: value });
  };

  displayCustomError({ message }) {
    this.setState({ customAlert: { visible: true, message } });
  }
  focusRefSet() {
    if (this.state.focusTarget === "weight") {
      this.weightRef.current.focus()
      return
    }
    if(this.state.focusTarget === "toState"){
      this.stateRef.current.focus()
      return
    }
    if(this.state.focusTarget === "toPostalCode"){
      this.postRef.current.focus()
      return
    }
    if(this.state.focusTarget === "toCountry"){
      this.countryRef.current.focus()
      return
    }
    if(this.state.focusTarget === "length"){

      this.lengthRef.current.focus()
      return
    }
    if(this.state.focusTarget === "width"){
      this.widthRef.current.focus()
      return
    }
    if(this.state.focusTarget === "height"){
      this.heightRef.current.focus()
      return
    }


  }
  fetchUpdatedRates() {
    this.handlePoundsChange();
    if (this.state.flag) {
      this.setState({ weightValidationError: true, fetchRatesVisibility: false })
    }
    else {
      const payload = this.state.fetchRatesData;
      let carrier_code = [];
      let expandedCarriers;
      //payload.carrier_code = this.state.expandedCarriers.join(',');

      if (this.state.activateEditButton) {
        expandedCarriers = this.state.contentVisibility;
      } else {
        expandedCarriers = this.state.carrierDropdownVisibilityNormalMode;
      }
      for (const prop in expandedCarriers) {
        if (expandedCarriers[prop]) carrier_code.push(prop);
      }

      if (!carrier_code.length) {
        this.displayCustomError({
          message: 'Please open atleast 1 carrier section before fetching rates'
        });
        return;
      }

      payload.carrier_code = carrier_code.join(',');
      this.props.fetchRealTimeRates(payload);
      this.changeOrderAddress(this.state.countryCode)
    }
  }

  fetchUpdatedRatesSelected(carrier_code) {
    const payload = this.state.fetchRatesData;
    payload.carrier_code = carrier_code;
    this.props.fetchRealTimeRatesSelected(payload);
  }

  changeOrderAddress(value) {
    if (this.state.flag) {
      this.setState({ weightValidationError: true, fetchRatesVisibility: false })
    }
    const data = {
      order_number: this.basicinfo.increment_id || '',
      ss_label_data: {
        shipping_address: {
          name: document.querySelector(
            '#orderAddressForm input[placeholder="Name"]'
          ).value,
          address1: document.querySelector(
            '#orderAddressForm input[placeholder="Address 1"]'
          ).value,
          address2: document.querySelector(
            '#orderAddressForm input[placeholder="Address 2"]'
          ).value,
          city: document.querySelector(
            '#orderAddressForm input[placeholder="City"]'
          ).value,
          state: document.querySelector(
            '#orderAddressForm input[placeholder="State"]'
          ).value,
          postal_code: document.querySelector(
            '#orderAddressForm input[placeholder="Postal Code"]'
          ).value,
          country: value
        },
        dimensions: this.state.selectedDimensionData,
        weight: {
          value:
          this.state?.pickerWeightSelectedOption !=='ounces' ?
              this.pounds : this.ounces,
          units: this.state.pickerWeightSelectedOption
        }
      }
    };
    this.props.updateOrderAddress(data);
  }

  // updateCarrierVisibility(data) {
  //   this.props.setCarrierVisibility(data);
  // }

  updateServiceVisibility(data) {
    this.props.setRateVisibility(data);
  }

  // updateContractedCarrier(data) {
  //   this.props.setContractedCarrier(data);
  // }

  mapPreselectedService = () => {
    let matchFound = false;
    if (this.state?.preselectedService) {
      this.state?.available_carriers?.forEach(carrier => {
        carrier?.rates?.forEach(rate => {
          if (rate.serviceName === this.state?.preselectedService) {
            matchFound = true;
            this.setSelectedServiceToPurchase({
              carrierCode: rate.carrierCode,
              serviceCode: rate.serviceCode,
              serviceName: rate.serviceName,
              purchaseConfirm: false
            });
          }
        });
      });
    }
    if (!matchFound) this.setState({ selectedServiceToPurchase: null });
  };

  setSelectedServiceToPurchase = ({
    carrierCode,
    serviceCode,
    serviceName,
    purchaseConfirm,
    packageCode,
  }) => {
    if (purchaseConfirm === true)
      this.setState(
        {
          selectedServiceToPurchase: {
            carrierCode,
            serviceCode,
            serviceName,
            packageCode
          }
        },
        () => {
          this.purchaseSelectedService();
        }
      );
    else
      this.setState({
        selectedServiceToPurchase: {
          carrierCode,
          serviceCode,
          serviceName,
          packageCode
        }
      });
  };

  purchaseSelectedService = () => {
    let dimesionsValue = this.state.fetchRatesData?.post_data?.dimensions
    let shipDateNew = moment(this.state?.pickedDate)
    const payload = {
      credential_id: this.props?.ssLabelData?.ss_label_data?.credential_id,
      post_data: {
        orderId: this.props?.ssLabelData?.ss_label_data?.orderId,
        carrierCode: this.state?.selectedServiceToPurchase?.carrierCode,
        serviceCode: this.state?.selectedServiceToPurchase?.serviceCode,
        confirmation: this.state?.pickerConfirmationSelectedOption,
        shipDate: shipDateNew.format("ddd, DD MMM YYYY"),
        packageCode: this.state?.selectedServiceToPurchase?.packageCode,
        weight: {
          value: this.state?.pickerWeightSelectedOption !=='ounces' ?
            this.state.poundsValue : this.state.ouncesValue || this.props.ssLabelData?.ss_label_data?.weight?.value,
          units: this.state?.pickerWeightSelectedOption
        },
        dimension:{
          height:dimesionsValue?.height,
          length:dimesionsValue?.length,
          units:dimesionsValue?.units,
          width:dimesionsValue?.width,
        }
      }
    };
    this.props.createSsLabel(payload);
  };

  mapShortcutsToInputs = shortcutObj => {
    let shortcuts = shortcutObj;
    if (shortcuts && Object.keys(shortcuts).length !== 0) {
      let newShortcutsInputs = {};
      for (const item in shortcuts) {
        if (shortcuts[item] === 'weight') {
          newShortcutsInputs = { ...newShortcutsInputs, weight: item };
        } else if (shortcuts[item] === 'dimension') {
          newShortcutsInputs = { ...newShortcutsInputs, dimension: item };
        } else if (shortcuts[item] === 'shipping address') {
          newShortcutsInputs = {
            ...newShortcutsInputs,
            ['shipping address']: item
          };
        } else if (shortcuts[item] === 'confirmation') {
          newShortcutsInputs = { ...newShortcutsInputs, confirmation: item };
        }
        else if(shortcuts[item] === 'ozWeight'){
          newShortcutsInputs = { ...newShortcutsInputs, ozWeight: item };
        }
        else if(shortcuts[item] === 'residential'){
          newShortcutsInputs = { ...newShortcutsInputs, residential: item };
        }
      }
      this.setState({ shortcutsInputs: newShortcutsInputs });
    }
  };

  mapShortcutKeysToAvailableCarriersState = shortcutObj => {
    let shortcuts = shortcutObj;
    if (
      this.state?.available_carriers?.length &&
      shortcuts &&
      Object.keys(shortcuts).length !== 0
    ) {
      const arr = this.state.available_carriers;
      arr.forEach((carrier, cI) => {
        carrier.rates?.forEach((rate, rI) => {
          rate.shortcutKey && delete rate.shortcutKey;
          for (const item in shortcuts) {
            if (shortcuts[item] === rate.serviceName) {
              arr[cI].rates[rI].shortcutKey = item.split('+');
            }
          }
        });
      });
      this.setState({ available_carriers: arr });
      const shorcutArr = [];
      for (const item in shortcuts) {
        shorcutArr.push({
          name: shortcuts[item],
          shortcutKey: item.split('+')
        });
      }
      this.setState({ shortcutKeysTotal: shorcutArr });
    }
  };

  mapShortcutKeysToDimensionsState = shortcutObj => {
    let shortcuts = shortcutObj;
    if (
      this.state?.dimensionData?.length &&
      shortcuts &&
      Object.keys(shortcuts).length !== 0
    ) {
      const arr = this.state.dimensionData;
      arr.forEach((dimension, cI) => {
        dimension.shortcutKey && delete dimension.shortcutKey;
        for (const item in shortcuts) {
          if (shortcuts[item] === dimension.presetName) {
            arr[cI].shortcutKey = item.split('+');
          }
        }
      });
      this.setState({ dimensionData: arr });
      const shorcutArr = [];
      for (const item in shortcuts) {
        shorcutArr.push({
          name: shortcuts[item],
          shortcutKey: item.split('+')
        });
      }
      this.setState({ shortcutKeysTotal: shorcutArr });
    }
  };

  decodeDimensions = ({ presetName, value }) => {
    let units, length, width, height;
    units = value.slice(value.indexOf('(') + 1, value.indexOf(')'));
    [length, width, height] = value.split('(')[0].split('x');
    return { length, width, height, units, presetName };
  };

  encodeDimensions = data => {
    const { length, width, height, units, presetName } = data;
    const value = `${length}x${width}x${height}(${units})`;
    return { presetName, value };
  };

  setDimensionPresets = (totalPresets, callback) => {
    let arr = [];
    for (const prop in totalPresets) {
      let obj = this.decodeDimensions({
        presetName: prop,
        value: totalPresets[prop]
      });
      arr.push(obj);
    }
    this.setState({ dimensionData: [...arr] }, () => {
      callback();
    });
  };

  toggleErrorAlert = ({ visible, message }) => {
    if (visible == true) {
      this.setState({ compError: { visible: true, message } });
    } else if (visible == false) {
      this.setState({ compError: { visible: false, message: null } });
    } else {
      this.setState(prev => ({
        compError: {
          ...prev.compError,
          visible: !prev.compError.visible,
          message: message || null
        }
      }));
    }
  };

  updateDisabledRates = fetchedCarriers => {
    if (!fetchedCarriers) return;
    let disableRates = this.state.disableRates || {};
    fetchedCarriers.forEach(carrier => {
      disableRates[carrier.code] = [];
      carrier.rates?.forEach(rate => {
        if (rate.visible === false) {
          disableRates[carrier.code].push(rate.serviceName);
        }
      });
    });
    this.setState({ disableRates });
  };

  defaultErrors = {
    length: `"request.dimensions.length"=>["A value is required."]`,
    width: `"request.dimensions.width"=>["A value is required."]`,
    height: `"request.dimensions.height"=>["A value is required."]`,
    weight: `"request.weight.value"=>["A value is required."]`,
    'country code': [
      `"request.toCountry"=>["The toCountry field is required."]`,
      `ExceptionMessage: Invalid ToCountry. Please use the two-character ISO country code`
    ]
  };

  mapErrorMessages(errorMsg, type) {
    if (!errorMsg) return 'There was an error! Try again later.';
    let invalidKeys = [];
    for (let prop in this.defaultErrors) {
      if (
        typeof this.defaultErrors[prop] === 'object' &&
        this.defaultErrors[prop].length
      ) {
        let match = false;
        this.defaultErrors[prop].forEach((el, i) => {
          if (errorMsg.indexOf(el) !== -1) {
            match = true;
          }
        });
        if (match) invalidKeys.push(prop);
      } else {
        if (errorMsg.indexOf(this.defaultErrors[prop]) !== -1) {
          invalidKeys.push(prop);
        }
      }
    }
    if (invalidKeys?.length) {
      if (type === 'rates')
        return `Please enter valid ${invalidKeys.join(
          ', '
        )} so rates can be requested.`;
      else if (type === 'purchase')
        return `Please enter valid ${invalidKeys.join(', ')}.`;
    } else {
      const index = errorMsg.indexOf('<br>');
      if (index) {
        let response = errorMsg.split('<br>')[1];
        if (!response.trim()) return errorMsg;
        return response;
      }
      return errorMsg;
    }
  }

  copyOrderNumberToClipboard = async value => {
    await Clipboard.setStringAsync(value);
  };

  hitApi = () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.CheckConnection();
    } else {
      this.apiCall();
    }
  };

  CheckConnection = async () => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      this.apiCall();
    } else {
      this.setState({ errorMessage: 'Please check your internet connection' });
    }
  };

  apiCall = () => {
    this.props.fetchLabelData({ id: this.basicinfo?.id, app: true });
  };

  componentDidMount() {
    document.activeElement.blur();
    this.hitApi();
    this.setState({ loading: true });
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('keydown', this.detectKeyPressed);
    //this.detectKeyPressed();
  }

  componentDidUpdate(previousProps, previousState) {
    if (
      (this.state.pickerResidentialSelectedOption !==
        previousState.pickerResidentialSelectedOption) ||
      (this.state.pickerConfirmationSelectedOption!==
        previousState.pickerConfirmationSelectedOption)
    ) {
      this.updateRatesData()
    }
    //Fetch Label Related Data
    let sslabelData = this.props?.ssLabelData?.ss_label_data
    let generalSetting = this.props?.bothSettings?.data?.data?.general_setting
    if(previousProps.ssLabelData !== sslabelData){
      const checkShipmentVoid = sslabelData?.shipments?.filter(item => item.voided == false).length > 0;
      if(this.state.showPreviouslyPrintedLabel !== checkShipmentVoid){
        this.setState({showPreviouslyPrintedLabel: checkShipmentVoid})
      }
    }
    if(previousState?.ssLabelData?.ss_label_data?.direct_printed != sslabelData?.direct_printed){
      if(sslabelData?.direct_printed){
       if(generalSetting?.print_ss_shipping_labels){
        if(!this.state.printlabelFun){
          this.printlabel(generalSetting,"")
           this.setState({printlabelFun:true})
          }
        }
       else{
          window.open(sslabelData?.direct_printed_url);
          this.props.navigation.navigate("ScanPack")
        }
      }
    }
    if (
      (!previousProps &&
        this.props.ssLabelData?.ss_label_data?.available_carriers) ||
      // && !this.props.ssLabelData?.ss_label_data?.available_carriers.some(el => el.errors)
      (previousProps &&
        previousProps.ssLabelData?.ss_label_data?.available_carriers !==
        this.props.ssLabelData?.ss_label_data?.available_carriers)
      //&& !this.props.ssLabelData?.ss_label_data?.available_carriers.some(el => el.errors)
    ) {
      //Fetch Label Error handling If Only Some Carriers Have Errors
      if (
        previousProps?.ssLabelData?.time !== this.props?.ssLabelData?.time &&
        this.props.ssLabelData?.ss_label_data?.available_carriers &&
        this.props.ssLabelData?.ss_label_data?.available_carriers.some(
          el => el.errors
        )
      ) {
        let carrierErrorObj = {};
        this.props.ssLabelData?.ss_label_data?.available_carriers.forEach(
          el => {
            if (el.errors) {
              carrierErrorObj[el.code] = this.mapErrorMessages(
                el.errors,
                'rates'
              );
            }
          }
        );
        this.setState(prev => ({
          carrierErrors: { ...prev.carrierErrors, ...carrierErrorObj }
        }));
      }
      this.setState({ loading: false });
      this.setInitialFormValues();
      this.props?.ssLabelData?.ss_label_data?.weight?.units === 'ounces' ?
        this.convertOzToLbs(this.props?.ssLabelData?.ss_label_data?.weight?.value) :
        this.convertGramsToKg(this.props?.ssLabelData?.ss_label_data?.weight?.value)

      this.setState(
        {
          available_carriers:
            this.props.ssLabelData?.ss_label_data?.available_carriers
        },
        () => {
          this.mapShortcutKeysToAvailableCarriersState(
            this.props.ssLabelData?.ss_label_data?.label_shortcuts
          );
          // this.props.labelShortcuts =
          //   this.props?.ssLabelData?.ss_label_data?.label_shortcuts;
          this.mapShortcutsToInputs(
            this.props?.ssLabelData?.ss_label_data?.label_shortcuts
          );
        }
      );
      //To set the rates visibility
      this.updateDisabledRates(
        this.props?.ssLabelData?.ss_label_data?.available_carriers
      );
      let contentVisibility = {};
      let carrierDropdownVisibility = {};
      let carrierDropdownVisibilityNormalMode = {};
      this.props.ssLabelData?.ss_label_data?.available_carriers?.forEach(el => {
        contentVisibility[el.code] = el.visible;
        carrierDropdownVisibility[el.code] = el.expanded;
        carrierDropdownVisibilityNormalMode[el.code] = el.expanded;
      });
      this.setState({ contentVisibility, carrierDropdownVisibility, carrierDropdownVisibilityNormalMode });
      //Set Dimension Presets
      if (
        this.props?.ssLabelData?.ss_label_data?.presets &&
        Object.keys(this.props?.ssLabelData?.ss_label_data?.presets).length != 0
      ) {
        this.setDimensionPresets(
          this.props?.ssLabelData?.ss_label_data?.presets,
          () => {
            this.mapShortcutKeysToDimensionsState(
              this.props.ssLabelData?.ss_label_data?.label_shortcuts
            );
          }
        );
        this.setState({
          selectedDimensionPreset:
            this.props?.ssLabelData?.ss_label_data?.dimensions?.presetName
        });
      }
      //Set default selected package to purchase
      if (
        this.props?.ssLabelData?.ss_label_data?.service &&
        this.props?.ssLabelData?.ss_label_data?.package
      ) {
        this.props.ssLabelData?.ss_label_data?.available_carriers.forEach(
          carrier => {
            carrier?.rates?.forEach(rate => {
              if (
                rate.serviceName ===
                this.props?.ssLabelData?.ss_label_data?.service?.name +
                ' - ' +
                this.props?.ssLabelData?.ss_label_data?.package?.name
              ) {
                this.setSelectedServiceToPurchase({
                  carrierCode:
                    this.props?.ssLabelData?.ss_label_data?.service
                      ?.carrierCode,
                  serviceCode:
                    this.props?.ssLabelData?.ss_label_data?.service?.code,
                  serviceName:
                    this.props?.ssLabelData?.ss_label_data?.service?.name +
                    ' - ' +
                    this.props?.ssLabelData?.ss_label_data?.package?.name,
                  purchaseConfirm: false
                });
              }
            });
          }
        );
        this.setState({
          preselectedService:
            this.props?.ssLabelData?.ss_label_data?.service?.name +
            ' - ' +
            this.props?.ssLabelData?.ss_label_data?.package?.name
        });
      }
    }
    //Fetch Label Error Handling
    if (
      previousProps?.ssLabelData?.time !== this.props?.ssLabelData?.time &&
      this.props?.ssLabelData?.status &&
      !this.props?.ssLabelData?.ss_label_data
    ) {
      this.toggleErrorAlert({
        visible: true,
        message: 'Something went wrong! try again later.'
      });
    }
    //Fetch Updated Rates
    if (
      this.props?.updatedAvailableCarriers?.ss_label_data?.available_carriers &&
      !this.props?.updatedAvailableCarriers?.selected &&
      this.props?.updatedAvailableCarriers?.time !==
      previousProps?.updatedAvailableCarriers?.time
    ) {
      //Fetch Updated Rates Error Handling If Only Some Carriers Have Errors
      if (
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers.some(
          el => el.errors
        )
      ) {
        let carrierErrorObj = {};
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers.forEach(
          el => {
            if (el.errors) {
              carrierErrorObj[el.code] = this.mapErrorMessages(
                el.errors,
                'rates'
              );
            }
          }
        );
        this.setState(prev => ({
          carrierErrors: { ...prev.carrierErrors, ...carrierErrorObj }
        }));
      }
      this.toggleSubmitButton(false);
      let fetchedCarriers =
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers;

      let availableCarriers = this.state.available_carriers;
      if (availableCarriers?.length) {
        fetchedCarriers.forEach(carrier => {
          //Remove error state of carriers if there are no errors in fetched data
          if (!carrier.errors) {
            this.setState(prev => {
              let errState = prev.carrierErrors;
              delete errState[carrier.code];
              return { carrierErrors: errState };
            });
          }
          const index = availableCarriers.findIndex(
            el => el.name === carrier.name
          );
          availableCarriers[index].rates = carrier.rates;
        });

        if (fetchedCarriers?.length) {
          let fetchedCode = fetchedCarriers.map(el => el.code);
          availableCarriers.forEach((stateCarrier, sI) => {
            if (fetchedCode.some(code => code === stateCarrier.code)) {
              const index = fetchedCarriers.findIndex(
                el => el.code === stateCarrier.code
              );
              availableCarriers[sI].rates = fetchedCarriers[index].rates;
            } else {
              availableCarriers[sI].rates = null;
            }
          });
        }
      } else availableCarriers = fetchedCarriers;

      this.setState(
        {
          available_carriers: availableCarriers
        },
        () => {
          this.mapShortcutKeysToAvailableCarriersState(
            this.props.labelShortcuts
          );
          this.mapShortcutsToInputs(this.props.labelShortcuts);
          //Set default selected package to purchase if it is present
          this.mapPreselectedService();
        }
      );
      //To set the rates visibility
      this.updateDisabledRates(fetchedCarriers);
    }
    //Fetch Updated Rates Error Handling
    if (
      this.props?.updatedAvailableCarriers?.time !==
      previousProps?.updatedAvailableCarriers?.time &&
      !this.props?.updatedAvailableCarriers?.status &&
      this.props?.updatedAvailableCarriers?.error_messages
    ) {
      this.toggleErrorAlert({
        visible: true,
        message: this.props.updatedAvailableCarriers.error_messages
      });
    }
    //Fetch Updated Rates Selected Carriers
    if (
      this.props?.updatedAvailableCarriers?.ss_label_data?.available_carriers &&
      this.props?.updatedAvailableCarriers?.selected &&
      this.props?.updatedAvailableCarriers?.time !==
      previousProps?.updatedAvailableCarriers?.time
    ) {
      //Fetch Updated Rates Selected Error Handling
      if (
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers.some(
          el => el.errors
        )
      ) {
        let carrierErrorObj = {};
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers.forEach(
          el => {
            if (el.errors) {
              carrierErrorObj[el.code] = this.mapErrorMessages(
                el.errors,
                'rates'
              );
            }
          }
        );
        this.setState(prev => ({
          carrierErrors: { ...prev.carrierErrors, ...carrierErrorObj }
        }));
      }
      //this.toggleSubmitButton(false);
      let fetchedCarrier =
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers[0]
          .name;
      let fetchedRates =
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers[0]
          .rates;
      let availableCarriers = this.state.available_carriers;
      const index = availableCarriers.findIndex(
        el => el.name === fetchedCarrier
      );
      availableCarriers[index].rates = fetchedRates;
      this.setState(
        {
          available_carriers: availableCarriers
        },
        () => {
          this.mapShortcutKeysToAvailableCarriersState(
            this.props.labelShortcuts
          );
          this.mapShortcutsToInputs(this.props.labelShortcuts);
          //Set default selected package to purchase if it is present
          this.mapPreselectedService();
        }
      );
      //Remove error state if there are no errors in fetched data
      if (
        !this.props.updatedAvailableCarriers.ss_label_data.available_carriers[0]
          .errors
      ) {
        this.setState(prev => {
          let errState = prev.carrierErrors;
          delete errState[
            this.props.updatedAvailableCarriers.ss_label_data
              .available_carriers[0].code
          ];
          return { carrierErrors: errState };
        });
      }
      //To set the rates visibility
      this.updateDisabledRates(
        this.props.updatedAvailableCarriers.ss_label_data.available_carriers
      );
    }
    //Fetch Updated Rates Error Handling
    if (
      this.props?.updatedAvailableCarriers?.time !==
      previousProps?.updatedAvailableCarriers?.time &&
      !this.props?.updatedAvailableCarriers?.status &&
      this.props?.updatedAvailableCarriers?.error_messages
    ) {
      this.toggleErrorAlert({
        visible: true,
        message: this.props.updatedAvailableCarriers.error_messages
      });
    }
    //Shortcuts
    if (
      previousProps.labelShortcuts &&
      previousProps.labelShortcuts !== this.props.labelShortcuts
    ) {
      this.mapShortcutKeysToAvailableCarriersState(this.props.labelShortcuts);
      this.mapShortcutsToInputs(this.props.labelShortcuts);
      this.mapShortcutKeysToDimensionsState(this.props.labelShortcuts);
    }
    //Shortcuts Error Handling
    if (
      previousProps.labelShortcuts &&
      previousProps.labelShortcuts !== this.props.labelShortcuts &&
      !this.props.labelShortcuts
    ) {
      this.toggleErrorAlert({
        visible: true,
        message: 'Save Shortcuts Error! try again later.'
      });
    }
    //Create SS Label
    if (
      previousProps.ssLabel?.time !== this.props.ssLabel?.time &&
      this.props.ssLabel?.status &&
      this.props.ssLabel?.url
    ) {
      this.setState({ purchaseFailAlert: false, purchaseSuccessAlert: true });
      let shippineLabelSetting = this.props?.bothSettings?.data?.data?.general_setting?.print_ss_shipping_labels;
      let newShippingLabelSetting = this.props?.route?.params?.directPrintingSetting

     if(shippineLabelSetting || newShippingLabelSetting ){

      this.printlabel(this.props?.bothSettings?.data?.data?.general_setting,newShippingLabelSetting)
     } else{
        window.open(this.props.ssLabel?.url)
        this.props.navigation.navigate('ScanPack');
      }
    }
    //Create SS Label Error Handling
    if (
      previousProps.ssLabel?.time !== this.props.ssLabel?.time &&
      !this.props.ssLabel?.status &&
      this.props.ssLabel?.error_messages
    ) {
      this.setState({ purchaseFailAlert: true });
    }
    //Set Presets
    if (
      previousProps?.presets?.time !== this.props?.presets?.time &&
      this.props?.presets.status === true
    ) {
      this.mapShortcutKeysToDimensionsState(this.props?.labelShortcuts);
    }
    //Set Presets Error Handling
    if (
      previousProps?.presets?.time !== this.props?.presets?.time &&
      this.props?.presets?.status !== true
    ) {
      this.toggleErrorAlert({
        visible: true,
        message: 'Save Presets Error! try again later.'
      });
    }
    //Set Contracted Carrier Error Handling
    if (
      previousProps?.contractedCarrier?.time !==
      this.props?.contractedCarrier?.time &&
      this.props?.contractedCarrier?.status !== true
    ) {
      this.toggleErrorAlert({
        visible: true,
        message: 'Save Contracted Carrier Error! try again later.'
      });
    }
  }
  //QZ tray integration code.
  printlabel =async (setting,newShippingLabelSetting) =>{
    let dimensionShipping= this?.props?.ssLabel?.dimensions.split('x')
    let messageUrl= this.props.ssLabel?.url
    let data = await fetchData();
    this.setState({printers:(JSON.parse(JSON.stringify(data.printersList)))})
    let shippingLabel =
      (await AsyncStorage.getItem(`print_ss_shipping_labels_${userId}`)) || '';
    if (shippingLabel.length === 0) {
      this.setState({modalVisible:true});
    } else {
      this.setState({modalVisible:false})

      setTimeout(() => {
        handlePrinterSelection(JSON.parse(shippingLabel), messageUrl,setting,"shipping_label",{width:dimensionShipping[0],height:dimensionShipping[1]});
        // handlePrinterSelection(JSON.parse(shippingLabel), messageUrl,setting,"shipping_label","");
      }, [3000]);
      this.props.navigation.navigate('ScanPack');
    }
  }


  printlabelNew =async (setting,newShippingLabelSetting,url) =>{
    let messageUrl= url === undefined ? this.props.ssLabel?.url : url
    this.setState({messageUrlReprint:url})
    let dimensionShipping=  this.props?.ssLabelData?.ss_label_data?.reprint_dimensions.split('x')
    let data = await fetchData();
    this.setState({printers:(JSON.parse(JSON.stringify(data.printersList)))})
    let shippingLabel =
      (await AsyncStorage.getItem(`print_ss_shipping_labels_${userId}`)) || '';
    if (shippingLabel.length === 0) {
      this.setState({modalVisibleNew:true});
    } else {
      this.setState({modalVisibleNew:false})

      setTimeout(() => {
        handlePrinterSelection(JSON.parse(shippingLabel), messageUrl,setting,"shipping_label",{width:dimensionShipping[0],height:dimensionShipping[1]});
        // handlePrinterSelection(JSON.parse(shippingLabel), messageUrl,setting,"shipping_label","");
      }, [3000]);
      this.props.navigation.navigate('ScanPack');
    }
  }

  componentWillUnmount() {
    this.setState({ available_carriers: [] });
    window.addEventListener('resize', this.updateDimensions);
    window.removeEventListener('keydown', this.detectKeyPressed);
  }

  ConditionalWrap = ({ condition, wrap, children }) =>
    condition ? wrap(children) : children;


  showPreviousBtton = () =>{
    this.setState({openReprintShipping:true})
  }
  closePreviousModel = () =>{
    this.setState({openReprintShipping :false,newState:false ,showNewState: true})
  }
  iconCancelPress = () =>{
    this.setState({openReprintShipping :false,newState:false})
  }
  render() {
    let { carrierLoading, purchaseLoading } = this.props;
    // let shippineLabelSetting=this.props?.bothSettings?.data?.data?.general_setting?.print_ss_shipping_labels;
    let permissions = {
      EDIT_VISIBLE_SERVICES: this.roles?.edit_visible_services || false,
      SHORTCUTS_WRITE: this.roles?.add_edit_shortcuts || false,
      DIMENSIONS_PRESETS_WRITE: this.roles?.add_edit_dimension_presets || false
    };

    const children = (
      <ScrollView
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}>
        <View style={[styles.container, { marginLeft: 0 }]} />
        <View
          style={
            this.state.windowWidth >= 1080
              ? styles.mainContainer
              : styles.mainContainerMobile
          }>
          {this.props?.route?.params?.isolatedComponent ? (
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.orderHeaderContainer
                  : styles.orderHeaderContainerMobile
              }>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: 'bold',
                  marginTop: 0,
                  fontFamily: 'Poppins_700Bold',
                  background:
                    '-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)',
                  WebkitTextFillColor: 'transparent',
                  webkitBackgroundClip: 'text'
                }}>
                Order {this.basicinfo?.increment_id}
                <TouchableOpacity
                  testID='copy-order-number-testId'
                  onPress={() => {
                    this.copyOrderNumberToClipboard(
                      this.basicinfo?.increment_id
                    )
                  }}>
                  <i
                    className="icon-docs"
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginLeft: '5px',
                      background:
                        '-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)',
                      WebkitTextFillColor: 'transparent',
                      webkitBackgroundClip: 'text'
                    }}></i>
                </TouchableOpacity>
              </Text>
              <View
                style={{
                  alignSelf: 'flex-end',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginTop: 5,
                  zIndex: 2
                }}>
                <TouchableOpacity
                  onPress={() => this.props.navigation.navigate('ScanPack')}
                  style={{
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#fff',
                    borderRadius: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: "#494848",
                    height: 30,
                    shadowColor: '#e853fa',
                    shadowOffset: { width: 0, height: 0.5 },
                    shadowRadius: 8,
                    elevation: 5,
                    shadowOpacity: 0.5
                  }}>
                  <Text
                    style={{
                      color: 'white',
                      paddingHorizontal: 10,
                      fontWeight: 'bold'
                    }}>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.orderInfoHeaderContainer
                  : styles.orderInfoHeaderContainerMobile
              }>
              {/* order header portion */}
              <OrderInfoHeader
                fetchRatesVisibility={this.state.fetchRatesVisibility}
                id={this.basicinfo}
                // headerStyle={styles.header}
                status={this.basicinfo?.status}
                updateOrderStatus={this.props.updateOrderStatus}
                onCancel={this.props.back}
                hideSaveBtn={true}
              />
            </View>
          )}

          {this.state.purchaseSuccessAlert ? (
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.errorAlertContainer
                  : styles.errorAlertContainerMobile
              }>
              <LinearGradient
                locations={[0, 1]}
                colors={['#5bbc31', '#479523', '#3f851d']}
                style={styles.errorAlertGradient}>
                <Text style={styles.errorAlertText}>Purchase Successful!</Text>
                <TouchableOpacity
                  style={styles.errorAlertTouchable}
                  onPress={() => {
                    this.setState({ purchaseSuccessAlert: false });
                  }}>
                  <i
                    className="icon-cancel"
                    style={{ fontSize: '22px', color: 'white' }}></i>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          ) : (
            <View></View>
          )}

          {this.state.purchaseFailAlert ? (
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.errorAlertContainer
                  : styles.errorAlertContainerMobile
              }>
              <LinearGradient
                locations={[0, 1]}
                colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
                style={styles.errorAlertGradient}>
                <Text style={styles.errorAlertText}>
                  {this.mapErrorMessages(
                    this.props.ssLabel?.error_messages,
                    'purchase'
                  )}
                </Text>
                <TouchableOpacity
                  style={styles.errorAlertTouchable}
                  onPress={() => {
                    this.setState({ purchaseFailAlert: false });
                  }}>
                  <i
                    className="icon-cancel"
                    style={{ fontSize: '22px', color: 'white' }}></i>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          ) : (
            <View></View>
          )}

          {this.state.compError.visible ? (
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.errorAlertContainer
                  : styles.errorAlertContainerMobile
              }>
              <LinearGradient
                locations={[0, 1]}
                colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
                style={styles.errorAlertGradient}>
                <Text style={styles.errorAlertText}>
                  {this.state.compError?.message}
                </Text>
                <TouchableOpacity
                  style={styles.errorAlertTouchable}
                  onPress={() => {
                    this.setState(prev => ({
                      compError: { ...prev.compError, visible: false }
                    }));
                  }}>
                  <i
                    className="icon-cancel"
                    style={{ fontSize: '22px', color: 'white' }}></i>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          ) : (
            <View></View>
          )}

          {this.state.customAlert.visible ? (
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.errorAlertContainer
                  : styles.errorAlertContainerMobile
              }>
              <LinearGradient
                locations={[0, 1]}
                colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
                style={styles.errorAlertGradient}>
                <Text style={styles.errorAlertText}>
                  {this.state.customAlert?.message}
                </Text>
                <TouchableOpacity
                  style={styles.errorAlertTouchable}
                  onPress={() => {
                    this.setState(prev => ({
                      customAlert: { ...prev.customAlert, visible: false }
                    }));
                  }}>
                  <i
                    className="icon-cancel"
                    style={{ fontSize: '22px', color: 'white' }}></i>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          ) : (
            <View></View>
          )}

          <View
            style={[
              globalStyles.m_10,
              this.state.windowWidth >= 1080
                ? styles.workArea
                : styles.workAreaMobile
            ]}>
            {/* Form left part section */}
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.leftPortion
                  : styles.leftPortionMobile
              }>
              {/* Ship Date section*/}
              {/* {this.state.windowWidth <= 1080 ?
              <View>
              {this.props?.ssLabelData?.ss_label_data?.shipments?.filter(item => item.voided == false).length > 0 &&
                  <View style={{alignItems: 'center'}}>
                    <TouchableOpacity onPress={() => this.showPreviousBtton()}>
                      <Text style={styles.previousStyleText}>See Previosly printed label</Text>
                    </TouchableOpacity>
                </View>
              }
              </View>:<></>
  } */}
              <View style={{ zIndex: -1 }}
                testID='Update-button-testId'
              >
                {this.state.fetchRatesVisibility && this.state.windowWidth > 900 && (
                  <UpdateRatesButton
                    windowWidth={this.state.windowWidth}
                    carrierLoading={this.props.carrierLoading}
                    handlePoundsChange={() => { this.handlePoundsChange() }}
                    fetchUpdatedRates={() => { this.fetchUpdatedRates() }}
                  />
                )}
              </View>
              <View style={{ zIndex: -1 }}>
                <Text style={[globalStyles.py_10, styles.labelStyle]}>
                  Ship Date
                </Text>
                <View
                  style={[
                    styles.roundedBox,
                    this.state.windowWidth >= 1080
                      ? styles.inputStyle
                      : styles.inputStyleMobile,
                    globalStyles.bgGrey,
                    { paddingVertical: 7 }
                  ]}>
                  <DateTimePicker
                    style={datestyle}
                    data-test-id="date-picker-test-Id"
                    // amPmAriaLabel={'Select AM/PM'}
                    // format="MM-dd-yyyy h:mm aa"
                    // minDate={this.defaultShipDate}
                    format="MM-dd-yyyy"
                    onChange={date => {
                      this.setState({ pickedDate: date });
                      this.changeInitialFormValues('orderDate', date);
                    }}
                    value={this.state.pickedDate}
                  />
                </View>
              </View>

              {/* Weight section */}

              <View style={{ marginTop: 5, zIndex: -2 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'start'
                  }}>
                  <View
                    style={{
                      marginRight: '-17px'
                    }}>
                    <Text
                      style={[
                        globalStyles.py_10,
                        this.state.weightFocus
                          ? styles.labelStyleFocus
                          : styles.labelStyle,
                        {
                          height: '64px',
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '0',
                          marginBottom: '0'
                        }
                      ]}>
                      Weight
                    </Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'}>
                      <TouchableOpacity
                        testID='weigthShortCutTestId'
                        onPress={() => this.addShortcutOther('weight')}>
                        <Text
                          style={[
                            this.state.weightFocus
                              ? styles.labelStyleFocus
                              : styles.labelStyle
                          ]}>
                          (
                          {this.state.shortcutsInputs?.weight ? (
                            this.state.shortcutsInputs.weight
                          ) : this.props.labelShortcuts ? (
                            <Image
                              source={shortcutImage}
                              style={styles.shortcutIconShowSmall}
                            />
                          ) : (
                            ''
                          )}
                          )
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID='shortBtnCutOz'
                        onPress={() => this.addShortcutOther('ozWeight')}>
                        <Text
                          style={[
                            this.state.weightFocus
                              ? styles.labelStyleFocus
                              : styles.labelStyle
                          ]}>
                          (
                          {this.state.shortcutsInputs?.ozWeight ? (
                            this.state.shortcutsInputs.ozWeight
                          ) : this.props.labelShortcuts ? (
                            <Image
                              source={shortcutImage}
                              style={styles.shortcutIconShowSmall}
                            />
                          ) : (
                            ''
                          )}
                          )
                        </Text>
                      </TouchableOpacity>
                    </Tooltip>
                    <span style={{ fontSize: "45px", paddingTop: "10px", color: "rgb(123, 250, 195)" }}>*</span>
                  </View>
                </View>

                <form
                  id="weightForm"
                  onSubmit={e => {
                    e.preventDefault();
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}>
                    <View style={{ width: this.state.windowWidth <= 425 ? '16%' : 307, height: 57, gap: 5 }}>
                      <View style={{ width: 146, height: 18 }} />
                      <View style={{ width: 207, height: 56, flexDirection: 'row', marginLeft: '16%' }}>
                        <Text style={[this.state.windowWidth <= 425 ? styles.weightStyleMobile : styles.weightStyle]}>
                          {this.state.pickerWeightSelectedOption === 'grams' ? 'KG' : 'LBS'}
                        </Text>
                        <TextInput
                          testID='changeWeightValue'
                          name="weight"
                          onSubmitEditing={() => this.fetchUpdatedRates()}
                          ref={this.weightRef}
                          autoFocus={true}
                          onFocus={e => e.target.select()}
                          onChangeText={(value) => {
                            this.handlePoundsChange();
                            this.changeInitialFormValues("weight", value)
                          }}
                          keyboardType="numeric"
                          style={[
                            styles.roundedBox,
                            this.state.windowWidth >= 1080
                              ? styles.inputStyle
                              : styles.inputStyleMobile,
                            globalStyles.bgGrey,
                            {
                              width: 57, height: 29, marginHorizontal: this.state.windowWidth <= 425 ? 1 : 10,
                              fontSize: 13
                            }
                          ]}
                          placeholder="Weight"
                          placeholderTextColor="grey"
                        />
                        <Text style={[this.state.windowWidth <= 425 ? styles.weightStyleMobile : styles.weightStyle]}>
                          {this.state.pickerWeightSelectedOption === 'grams' ? 'G' : 'OZ'}
                        </Text>
                        <TextInput
                          testID='poundsWeight-testId'
                          name="ozweight"
                          onSubmitEditing={() => this.fetchUpdatedRates()}
                          ref={this.ozWeightRef}
                          autoFocus={true}
                          onFocus={e => e.target.select()}
                          onChangeText={value => {
                            this.handlePoundsChange();
                            this.changeInitialFormValues('weight', value)
                          }}
                          keyboardType="numeric"
                          style={[
                            styles.roundedBox,
                            this.state.windowWidth >= 1080
                              ? styles.inputStyle
                              : styles.inputStyleMobile,
                            globalStyles.bgGrey,
                            {
                              width: 57, height: 29,
                              marginHorizontal: this.state.windowWidth <= 425 ? -4 : 10,
                              fontSize: 13
                            }
                          ]}
                          placeholder="WeightOunces"
                          placeholderTextColor="grey"
                        />
                      </View>
                    </View>
                    <View style={{
                      borderBottomColor: '#fff',
                      borderBottomWidth: 2,
                      marginLeft: this.state.windowWidth <= 425 && 130
                    }}
                    >
                      <Text style={[styles.unitsStyle, { width: this.state.windowWidth <= 425 && 110 }]}>
                        Units
                      </Text>
                      <View style={{ flexDirection: 'row' }}
                        testID='poundsWeight-testId'
                      >
                        {Platform.OS === 'ios' || Platform.OS === 'android' ? (
                          <Dropdown
                            selectedValue={this.state.pickerWeightSelectedOption}
                            data={this.state.pickerSelectedOptionsWeight}
                            onSelect={value => {
                              this.setState({
                                pickerWeightSelectedOption: value
                              });
                              this.changeInitialFormValues('weightUnit', value);
                            }}
                            pickerOpen={this.state.pickerWeightOpen}
                            setPickerOpen={this.setPickerWeightOpen}
                          />
                        ) : (
                          <select
                            data-test-id="select-item-test-id"
                            class="customSelect"
                            id="weight"
                            style={{
                              cursor: 'pointer',
                              width: this.state.windowWidth <= 425 ? 86 : '134px',
                              height: '35px',
                              backgroundColor: 'transparent',
                              borderStyle: 'none',
                              color: '#fff',
                              fontSize: 14,
                              overflow: 'visible',
                            }}
                            value={this.state.pickerWeightSelectedOption}
                            ref={this.confirmationRef}
                            data-focusvisible-polyfill={true}
                            onChange={event => {
                              this.setState({
                                pickerWeightSelectedOption: event.target.value
                              });
                              this.handlePoundsChange();
                              event.target.value === 'ounces' ? this.convertGramsToPoundsOunces(this.pounds) : this.convertOuncesToKgAndGrams(this.ounces)

                              this.changeInitialFormValues(
                                'weightUnit',
                                event.target.value
                              );
                              // debugger
                            }}>
                            {this.state.pickerSelectedOptionsWeight.map(el => {
                              return (
                                <option
                                  testID='selectOption'
                                  style={{
                                    backgroundColor: '#dbdada',
                                    color: '#000',
                                    fontSize: 14
                                  }}
                                  value={el.value}>
                                  {el.label}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        <Tooltip tooltipText={i18n.t('directprinting.tooltipWeights')} position={'top'} shipping={'true'} ssWeight={'true'}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px'
                            }}
                          ></i>
                        </Tooltip>
                      </View>
                    </View>
                  </View>
                </form>
              </View>

              {/* Dimension component Section */}
              <Dimension
                data-test-id='dimension-testId'
                dimensionRef={this.dimensionRef}
                focusRefSet={this.focusRefSet}
                lengthRef={this.lengthRef}
                widthRef={this.widthRef}
                confirmationRef={this.confirmationRef}
                heightRef={this.heightRef}
                focusTarget={this.state.focusTarget}
                fetchUpdatedRates={() => this.fetchUpdatedRates()}
                shortcutsInputs={this.state.shortcutsInputs}
                activateEditButton={this.state.activateEditButton}
                addShortcutOther={this.addShortcutOther}
                addShortcutDimension={this.addShortcutDimension}
                dimensionDataParent={this.state.dimensionData}
                setDimensionDataParent={this.setDimensionData}
                selectedDimensionData={this.state.selectedDimensionData}
                setSelectedDimensionData={this.setSelectedDimensionData}
                selectedDimensionPreset={this.state.selectedDimensionPreset}
                setSelectedDimensionPreset={this.setSelectedDimensionPreset}
                changeInitialFormValues={this.changeInitialFormValues}
                pickerDimensionUnit={this.state.pickerDimensionUnit}
                setPickerDimensionUnit={this.setPickerDimensionUnit}
                labelShortcuts={this.props.labelShortcuts}
                permissions={permissions}
                savePresets={this.props.setPresets}
                credentialId={
                  this.props?.ssLabelData?.ss_label_data?.credential_id
                }
                encodeDimensions={this.encodeDimensions}
                decodeDimensions={this.decodeDimensions}
                pickerDimensionOpen={this.state.pickerDimensionOpen}
                setPickerDimensionOpen={this.setPickerDimensionOpen}
                pickerWeightSelectedOption={this.state.pickerWeightSelectedOption}
              />

              {/* Shipping Address section */}

              <View style={{ zIndex: 0 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'start'
                  }}>
                  <View style={{ marginRight: '-17px' }}>
                    <Text
                      style={[
                        globalStyles.py_10,
                        styles.labelStyle,
                        {
                          height: '64px',
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '0',
                          marginBottom: '0'
                        }
                      ]}>
                      Shipping Address
                    </Text>
                  </View>
                  <View>
                    <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'}>
                      <TouchableOpacity testID='shippingAddressTestId'
                        onPress={() =>
                          this.addShortcutOther('shipping address')
                        }>
                        <Text
                          style={[
                            styles.labelStyle,
                            { display: 'flex', alignItems: 'center' }
                          ]}>
                          (
                          {this.state.shortcutsInputs?.['shipping address'] ? (
                            this.state.shortcutsInputs['shipping address']
                          ) : this.props.labelShortcuts ? (
                            <Image
                              source={shortcutImage}
                              style={styles.shortcutIconShowSmall}
                            />
                          ) : (
                            ''
                          )}
                          )
                        </Text>
                      </TouchableOpacity>
                    </Tooltip>
                  </View>
                </View>
                <form id="orderAddressForm">
                  <TextInput
                    name="toName"
                    testID='name-testId'
                    ref={this.addressRef}
                    onFocus={e => e.target.select()}
                    onChangeText={e =>
                      this.changeInitialFormValues('toName', e)
                    }
                    onBlur={() => {
                      this.changeOrderAddress();
                    }}
                    style={[
                      styles.roundedBox,
                      this.state.windowWidth >= 1080
                        ? styles.inputStyle
                        : styles.inputStyleMobile,
                      globalStyles.bgGrey
                    ]}
                    placeholder="Name"
                    placeholderTextColor="grey"
                  />
                  <TextInput
                    name="toAddress1"
                    testID='address-testId'
                    ref={this.address1Ref}
                    onFocus={e => e.target.select()}
                    onChangeText={value =>
                      this.changeInitialFormValues('toAddress1', value)
                    }
                    onBlur={() => {
                      this.changeOrderAddress();
                    }}
                    style={[
                      styles.roundedBox,
                      this.state.windowWidth >= 1080
                        ? styles.inputStyle
                        : styles.inputStyleMobile,
                      globalStyles.bgGrey
                    ]}
                    placeholder="Address 1"
                    placeholderTextColor="grey"
                  />
                  <TextInput
                    name="toAddress2"
                    testID='address2-testId'
                    onFocus={e => e.target.select()}
                    onChangeText={value =>
                      this.changeInitialFormValues('toAddress2', value)
                    }
                    onBlur={() => {
                      this.changeOrderAddress();
                    }}
                    style={[
                      styles.roundedBox,
                      this.state.windowWidth >= 1080
                        ? styles.inputStyle
                        : styles.inputStyleMobile,
                      globalStyles.bgGrey
                    ]}
                    placeholder="Address 2"
                    placeholderTextColor="grey"
                  />
                  <TextInput
                    name="toCity"
                    testID='city-testId'
                    onFocus={e => e.target.select()}
                    onChangeText={value =>
                      this.changeInitialFormValues('toCity', value)
                    }
                    onBlur={() => {
                      this.changeOrderAddress();
                    }}
                    style={[
                      styles.roundedBox,
                      this.state.windowWidth >= 1080
                        ? styles.inputStyle
                        : styles.inputStyleMobile,
                      globalStyles.bgGrey
                    ]}
                    placeholder="City"
                    placeholderTextColor="grey"
                  />
                  <TextInput
                    name="toState"
                    testID='state-testId'
                    onFocus={e => e.target.select()}
                    ref={this.stateRef}
                    onChangeText={value =>
                      this.changeInitialFormValues('toState', value)
                    }
                    onSubmitEditing={() => {
                      this.changeOrderAddress();
                    }}
                    style={[
                      styles.roundedBox,
                      this.state.windowWidth >= 1080
                        ? styles.inputStyle
                        : styles.inputStyleMobile,
                      globalStyles.bgGrey
                    ]}
                    placeholder="State"
                    placeholderTextColor="grey"
                  />
                  <TextInput
                    name="toPostalCode"
                    testID='postal-testId'
                    onFocus={e => e.target.select()}
                    onChangeText={value =>
                      this.changeInitialFormValues('toPostalCode', value)
                    }
                    ref={this.postRef}
                    onBlur={() => {
                      this.changeOrderAddress();
                    }}
                    style={[
                      styles.roundedBox,
                      this.state.windowWidth >= 1080
                        ? styles.inputStyle
                        : styles.inputStyleMobile,
                      globalStyles.bgGrey
                    ]}
                    placeholder="Postal Code"
                    placeholderTextColor="grey"
                  />
                  <View
                    testID='selectCountry'
                    style={{
                      paddingLeft: 13
                    }}
                  >
                    <CommonSelect
                      value={this.state.countryCode}
                      onFocus={e => e.target.select()}
                      refProp={this.countryRef}
                      list={CountryCodeList}
                      onChange={(value) => {
                        this.changeInitialFormValues('toCountry', value);
                        this.changeOrderAddress(value);
                        this.setState({ countryCode: value })
                      }
                      }
                      FocusVisible={true}
                      width={this.state.windowWidth > 900 ? '60%' : '96%'}
                    />
                  </View>
                </form>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: this.state.windowWidth <= 425 ? 'column' : 'row',
                width: this.state.windowWidth <= 425 && 320
              }}>
                <ShippingDropDown
                  id={'test'}
                  data-test-id='shipping-dropdown-testId'
                  showTooltip={false}
                  name={"Confirmation"}
                  input={"confirmation"}
                  windowWidth={this.state.windowWidth}
                  shortcutsInputs={this.state.shortcutsInputs?.confirmation}
                  labelShortcuts={this.props.labelShortcuts}
                  pickerConfirmationSelectedOption={this.state.pickerConfirmationSelectedOption}
                  pickerSelectedOptionsConfirmation={this.state.pickerSelectedOptionsConfirmation}
                  setPickerConfirmationOpen={(value) => { this.setPickerConfirmationOpen(value) }}
                  setPickerConfirmationSelectedOption={(value) => { this.setState({ pickerConfirmationSelectedOption: value, fetchRatesVisibility: true }) }}
                  changeInitialFormValues={this.changeInitialFormValues}
                  addShortcutOther={this.addShortcutOther}
                  confirmationRef={this.confirmationRef}
                />
                <ShippingDropDown
                  data-test-id='shipping-dropdown2-testId'
                  id={"residentialId"}
                  showTooltip={true}
                  name={"Address Type"}
                  input={"residential"}
                  windowWidth={this.state.windowWidth}
                  shortcutsInputs={this.state.shortcutsInputs?.residential}
                  labelShortcuts={this.props.labelShortcuts}
                  pickerConfirmationSelectedOption={this.state.pickerResidentialSelectedOption}
                  pickerSelectedOptionsConfirmation={this.state.pickerResidentialOptions}
                  setPickerConfirmationOpen={(value) => { this.setPickerConfirmationOpen(value) }}
                  setPickerConfirmationSelectedOption={(value) => { this.setState({ pickerResidentialSelectedOption: value, fetchRatesVisibility: true }) }}
                  changeInitialFormValues={this.changeInitialFormValues}
                  addShortcutOther={this.addShortcutOther}
                  confirmationRef={this.residentialRef}
                  zindex={this.state.windowWidth > 425 && '-10'}
                />
              </View>
              <View>
                {this.state.fetchRatesVisibility && (
                  <UpdateRatesButton
                    windowWidth={this.state.windowWidth}
                    carrierLoading={this.props.carrierLoading}
                    handlePoundsChange={() => { this.handlePoundsChange() }}
                    fetchUpdatedRates={() => { this.fetchUpdatedRates() }}
                    istop='true'
                  />
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'start'
                }}></View>
            </View>

            {/*Form Right part Section*/}
            <View
              style={
                this.state.windowWidth >= 1080
                  ? styles.rightPortion
                  : styles.rightPortionMobile
              }>
              <View style={this.state.windowWidth <= 1080 ? styles.previousStyleBtnMobile : styles.previousStyleBtn} >
                <View>
                  {this.props?.ssLabelData?.ss_label_data?.shipments?.filter(item => item.voided == false).length > 0 &&
                    <View dataSet={{ componentName: 'previoslyPrintedLabel' }}>
                      <TouchableOpacity onPress={() => this.showPreviousBtton()}>
                        <Text style={styles.previousStyleText}>See Previosly printed label</Text>
                      </TouchableOpacity>
                    </View>
                  }
                </View>
                {/* Edit Button Section */}
              </View>
              <View
                style={
                  this.state.windowWidth >= 900
                    ? styles.fetchEditContainer
                    : styles.fetchEditContainerMobile
                }>
                <View
                  style={{
                    flexDirection: 'row',
                    marginLeft: this.state.windowWidth >= 700 ? "0%" : "22%"
                  }}>
                  {this.state.weightValidationError && (
                    <TouchableOpacity
                      disabled={this.props.carrierLoading ? true : false}
                      onPress={() => this.focusRefSet()}>
                      <LinearGradient
                        colors={['#7BFAC3', '#1CBB77']}
                        locations={[0.1, 0.9]}
                        style={{
                          borderRadius: 10,
                          marginLeft: 12,
                          textAlign: 'center',
                          position: 'relative',
                          bottom: 65
                        }}>
                        {this.state.windowWidth >= 900 ? <Text style={styles.requireDataButton}><span style={{ fontSize: 22, lineHeight: "26px" }}>Missing Data</span> {'\n'}please complete required fields to fetch rates</Text> : <Text style={styles.editRatesButton}><span style={{ fontSize: 22 }}>TAP</span> {'\n'}To update Rates</Text>}
                      </LinearGradient>
                    </TouchableOpacity>
                  )}
                </View>
                {permissions.EDIT_VISIBLE_SERVICES && (!this.state.showPreviouslyPrintedLabel || this.state.showNewState) ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      top: this.state.fetchRatesVisibility && this.state.windowWidth <= 1080 && '-40px',
                      // height: 31
                    }}>
                    {this.state.available_carriers && (
                      <TouchableOpacity
                        testID='EditButton'
                        disabled={this.props.carrierLoading ? true : false}
                        style={{
                          ...styles.editBox,
                          flexDirection: 'row',
                          alignSelf: 'center',
                          marginRight: 12
                        }}
                        onPress={this.onclickEditButton}>
                        {this.state.activateEditButton ? (
                          <Text style={this.state.windowWidth <= 1080 ? styles.editButtonMobile : styles.editButton}>Done Editing</Text>
                        ) : (
                          <>
                            <i
                              className="icon-edit"
                              style={{ fontSize: '18px', color: 'white' }}></i>
                            {this.state.windowWidth >= 900 ?
                              <Text style={styles.editButton}>
                                Edit Visible Services
                              </Text>
                              : <></>}
                          </>
                        )}
                      </TouchableOpacity>
                    )}
                  </View>
                ) : (
                  <View style={{ ...styles.editBox, height: 31 }}></View>
                )}
              </View>
              {carrierLoading ? (
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <ActivityIndicator
                    size="large"
                    color="#336599"
                    style={{ marginTop: '80px' }}
                  />
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: 500,
                      textAlign: 'center',
                      color: '#fff'
                    }}>
                    {this.state.loaderTitleCarrier}
                  </Text>
                </View>
              ) : (
                (!this.state.showPreviouslyPrintedLabel || this.state.showNewState) && this.state?.available_carriers?.map((el, i) => {
                  return (
                    <>
                      <Panel
                        key={i}
                        data-test-id="panel-testId"
                        id={el.code}
                        iconColor="white"
                        title={el.name}
                        titleStyle={styles.companyName}
                        contentVisible={this.state.contentVisibility[el.code]}
                        carrierVisible={el.visible}
                        carrierDropdownVisibility={
                          this.state.carrierDropdownVisibility[el.code]
                        }
                        carrierDropdownVisibilityNormalMode={
                          this.state.carrierDropdownVisibilityNormalMode[
                          el.code
                          ]
                        }
                        compressExpandCarrier={() =>
                          this.compressExpandCarrier(el.code)
                        }
                        toggleCarrierDropdownVisibility={() =>
                          this.toggleCarrierDropdownVisibility(el.code)
                        }
                        toggleCarrierDropdownVisibilityNormalMode={() =>
                          this.toggleCarrierDropdownVisibilityNormalMode(
                            el.code
                          )
                        }
                        showHideCarrier={() => this.showHideCarrier(el.code, i)}
                        activateEditButton={this.state.activateEditButton}>
                        <PanelContent
                          data-test-id="panel-content-testId"
                          weightValidationError={this.state.weightValidationError}
                          addShortcut={this.addShortcut}
                          fetchRatesButton={this.state.fetchRatesVisibility}
                          activateEditButton={this.state.activateEditButton}
                          currentCarrier={el}
                          shortcutKeyCombination={
                            this.state.shortcutKeyCombination
                          }
                          toggleServiceVisibility={this.toggleServiceVisibility}
                          setSelectedServiceToPurchase={(params) => {
                            this.handlePoundsChange();
                            this.setSelectedServiceToPurchase(params);
                          }
                          }
                          selectedServiceToPurchase={
                            this.state.selectedServiceToPurchase
                          }
                          permissions={permissions}
                          carrierErrors={this.state.carrierErrors}
                        />
                      </Panel>
                    </>
                  );
                })
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    );

    return (
      <>
        {this.state.modalVisibleNew && (
          <PrinterShow
            dataPrinter={this.state.printers}
            handlePrinterSelection={async printer => {
              let directPrint = this.props?.ssLabelData?.ss_label_data?.direct_printed
              let messageUrl = this.state.messageUrlReprint
              let printerState = await handlePrinterSelection(printer, messageUrl, this.props?.bothSettings?.data?.data?.general_setting, "shipping_label", "");
              this.setState({ modalVisible: false })
              this.setState({ selectedPrinter: printerState.type })
              this.props.navigation.navigate('ScanPack');
            }}
            closePrompt={() => {
              this.setState({ modalVisible: false })
            }}
          ></PrinterShow>
        )}
        {this.state.modalVisible && (
          <PrinterShow
            dataPrinter={this.state.printers}
            handlePrinterSelection={async printer => {
              let directPrint = this.props?.ssLabelData?.ss_label_data?.direct_printed
              // let dimensionShipping= directPrint? this.props?.ssLabelData?.ss_label_data?.dimensions.split('x') : this?.props?.ssLabel?.dimensions.split('x')
              let messageUrl = directPrint ? this.props?.ssLabelData?.ss_label_data?.direct_printed_url : this.props.ssLabel?.url
              let printerState = await handlePrinterSelection(printer, messageUrl, this.props?.bothSettings?.data?.data?.general_setting, "shipping_label", "");
              this.setState({ modalVisible: false })
              this.setState({ selectedPrinter: printerState.type })
              this.props.navigation.navigate('ScanPack');
            }}
            closePrompt={() => {
              this.setState({ modalVisible: false })
            }}
          ></PrinterShow>
        )}
        {(this.state.newState || this.state.openReprintShipping) &&
          <ReprintSSlabel
            sslabelData={this.props?.ssLabelData?.ss_label_data}
            closePreviousModel={this.closePreviousModel}
            iconCancelPress={this.iconCancelPress}
            printlabelNew={this.printlabelNew}
            setting={this.props?.bothSettings?.data?.data?.general_setting}
            navigation={this.props.navigation}
          />
        }
        {/* {this.state.modalQZ&&<AlertBoxSaving name ={i18n.t('directprinting.printComplete')} closeAlert ={ ()=>{this.closeAlert()}}/>} */}
        <>
          <this.ConditionalWrap
            condition={this.props?.route?.params?.isolatedComponent}
            wrap={wrappedChildren => (
              <LinearGradient
                colors={[
                  '#000',
                  '#000',
                  '#5c778f',
                  '#5c778f',
                  '#253c57',
                  '#253c57',
                  '#000'
                ]}
                start={[-0.5, -1]}
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: -1,
                  position: 'absolute'
                }}>
                {wrappedChildren}
              </LinearGradient>
            )}>
            {children}
          </this.ConditionalWrap>

          <ShortcutModal
            style={datestyle}
            data-test-id="shortcut-test-Id"
            onClose={this.onCloseModal}
            toggleModal={this.state.showModal}
            saveShortcut={this.saveShortcut}
            shortcutKey={this.state.shortcutKeyCombo.shortcutKey}
            handleShortcutRetry={this.handleShortcutRetry}
          />
        </>
        {purchaseLoading && (
          <View
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#000',
              opacity: '60%',
              zIndex: 2147483647
            }}>
            <ActivityIndicator size="large" color="#fff" />
            <Text
              style={{
                fontSize: 13,
                fontWeight: 500,
                textAlign: 'center',
                color: '#fff'
              }}>
              Loading...
            </Text>
          </View>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    ssLabelData: state.order.ssLabelData,
    orderAddress: state.order.orderAddress,
    updatedAvailableCarriers: state.order.updatedAvailableCarriers,
    carrierLoading: state.order.carrierLoading,
    carrierVisibility: state.order.carrierVisibility,
    rateVisibility: state.order.rateVisibility,
    ssLabel: state.order.ssLabel,
    labelShortcuts: state?.order?.labelShortcuts?.label_shortcuts || state?.order?.ssLabelData?.ss_label_data?.label_shortcuts,
    purchaseLoading: state.order.purchaseLoading,
    contractedCarrier: state.order.contractedCarrier,
    presets: state.order.dimensionPresets
  };
};

const mapDispatchToProps = {
  fetchLabelData,
  updateOrderAddress,
  fetchRealTimeRates,
  fetchRealTimeRatesSelected,
  setCarrierVisibility,
  setRateVisibility,
  createSsLabel,
  setLabelShortcut,
  setContractedCarrier,
  setPresets
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingLabel);
